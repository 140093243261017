import {  toast } from 'react-toastify';


export const SuccessToast=({message})=>{
    toast.success(message, {
        position: "top-right",
        autoClose:3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
}

export const ErrorToast=({message})=>{
    toast.error(message, {
        position: "top-right",
        autoClose:3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
}
